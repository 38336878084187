.login-page{
     width: 100vw;
     height: 100vh;
     display: flex;
     align-items: center;
     justify-content: center;
     padding:30px;
     background-color: #ffffff;
}
.login-form{
     width:80vw;
     padding: 30px;
     border: 1px solid black;
     box-shadow: 13px 13px 6px 3px grey;
     border-radius: 10px;
     background-color: #000000;
}

.home-page{
     width: 100vw;
     /* height: 100vh; */
     background-color: #050011;
     overflow-y: scroll;
     overflow-x: hidden;
     display: flex;
     flex-direction: column;
     gap: 40px;
     padding: 20px;
}
.customer{
     height: 30vh;
     background-color: #393737;
}
.activation{
     height: 15vh;
     background-color: #393737;
}
.transactions{
     height: fit-content;
     background-color: #393737;
}
.nav{
     background-color: #393737;
}
.nav-icons{
     display: flex;
     gap: 250px;
     align-items: center;
     
}


/* Toggle button styles */
.toggle-btn {
     position: absolute;
     top: 20px;
     left: 25px;
     z-index: 999;
     cursor: pointer;
   }
   
   .toggle-btn .bi {
     font-size: 1.5rem;
   }
   
   /* Sidebar styles */
   .sidebar {
     position: fixed;
     top: 0;
     left: 0;
     width: 250px;
     height: 100%;
     background-color: #333;
     padding-top: 50px; 
     transition: transform 0.3s ease;
     transform: translateX(-100%);
   }
   
   .sidebar.open {
     transform: translateX(0);
   }
   
   .sidebar ul {
     list-style: none;
     padding: 0;
   }
   
   .sidebar ul li {
     padding: 10px;
   }
   
   .sidebar ul li a {
     color: white;
     text-decoration: none;
   }
   
   .sidebar ul li a:hover {
     color: #bbb; 
   }
   
   /* Main content styles */
   .content {
     margin-left: 40px;
     transition: margin-left 0.3s ease;
   }
   
   .content.sidebar-open {
     margin-left: 0;
   }


   .image{
     width: 2em;
     height: 2em;
     border-radius: 100px;
   }

   .locked{
     font-size: 2em;
     color: red;
   }

   /* Base styles for desktop */
.sc-login-drop {
     display: none; /* Initially hide dropdown */
   }
   
   /* Styles for mobile devices (adjust as needed) */
   @media (max-width: 768px) {
     .sc-login-drop {
       display: block; /* Show dropdown on mobile */
       /* Additional mobile-specific styles */
     }
   }
   
   /* Toggle visibility based on state */
   .sc-login-drop.active {
     display: block; /* Show dropdown when active */
   }
   .pointer{
     cursor: pointer;
}